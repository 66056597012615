var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4931cfa25bb0b683e1cb06bf4d4b685be33a48b2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://1dd9fbcda276456dac2cbcde74340923@sentry.io/3578543",
  integrations: integrations =>
    integrations.filter(integration => integration.name !== "Replay"),

  tracesSampleRate: 0.05,
});
